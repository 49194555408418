<template>
    <div class="container">
        <div id="header"><img class="float-left" src="/img/logo.jpg" id="img_logo"/></div>
        <a href="#" @click="SwitchLanguage">Français / Anglais</a>
        <hr/>
        <el-steps
            :active="$store.state.currentstep"
            align-center
            finish-status="success"
            align="center"
        >
            <el-step :title="$store.state.language.app_page0"/>
            <el-step :title="$store.state.language.app_page1"/>
            <el-step :title="$store.state.language.app_page2"/>
            <el-step :title="$store.state.language.app_page3"/>
            <el-step :title="$store.state.language.app_page4"/>
            <el-step :title="$store.state.language.app_page5"/>
            <el-step :title="$store.state.language.app_page6"/>
            <el-step :title="$store.state.language.app_page7"/>
        </el-steps>
        <hr/>
        <transition name="fade" mode="out-in">
            <router-view></router-view>
        </transition>
        <div class="footer">
            <span id="version">{{$store.state.language.version}} : {{$config.version}}</span>
        </div>
    </div>
</template>

<script>
    import {commonsettings} from './mixins'

    export default {
        mixins : [commonsettings],
        data() {
            return {
                current_lang : "fr"
            }
        },
        created(){
            if(this.$store.state.form.email.id === -1)
            {
                this.$router.push("/");
            }
            window.onbeforeunload = () => {
                return "Voulez-vous vraiment quitter ? (Le formulaire ne sera pas sauvegarder)";
            };
            this.$store.commit('updateCreateDate',this.FormatDate());
            this.$store.commit('updateLanguage', this.$lang_fr);
        },
        methods :{
            SwitchLanguage() {
                if(this.current_lang ==="fr")
                {
                    this.current_lang = "en";
                    this.$store.commit('updateLanguage', this.$lang_en);
                }
                else
                {
                    this.current_lang = "fr";
                    this.$store.commit('updateLanguage', this.$lang_fr);
                }
            }

        }
    }
</script>

<style>

</style>
