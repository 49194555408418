<template>
    <div>
        <div class="row form-inline form-group">
            <div class="col-md-1">
                <label>{{$store.state.language.requestinfo_name}}</label>
            </div>
            <!-- NOM -->
            <div class="col-md-3">
                <input
                    id="name"
                    name="name"
                    :class="errors.has('name') ? 'form-control full-width glowing-border':'form-control full-width'"
                    v-model.trim="requestinfo.name"
                    v-validate="{required: true}"
                    @input="onUpdated"
                />
                <span v-show="errors.has('name')" class="error">
                    {{ $store.state.language.required }}
                </span>
            </div>
            <!-- PRÉNOM -->
            <div class="col-md-1">
                <label>{{ $store.state.language.requestinfo_surname }}</label>
            </div>
            <div class="col-md-3">
                <input
                    id="surname"
                    name="surname"
                    v-model.trim="requestinfo.surname"
                    :class="errors.has('surname') ? 'form-control full-width glowing-border':'form-control full-width'"
                    v-validate="{required: true}"
                    @input="onUpdated"
                />
                <span v-show="errors.has('surname')" class="error">
                    {{ $store.state.language.required }}
                </span>
            </div>
            <!-- TITRE EMPLOI -->
            <div class="col-md-1">
                <label>{{ $store.state.language.requestinfo_jobtitle }}</label>
            </div>
            <div class="col-md-3">
                <input
                    id="jobtitle"
                    name="jobtitle"
                    v-model.trim="requestinfo.jobtitle"
                    :class="errors.has('jobtitle') ? 'form-control full-width glowing-border':'form-control full-width'"
                    v-validate="{required: true}"
                    @input="onUpdated"
                />
                <span v-show="errors.has('jobtitle')" class="error">
                    {{ $store.state.language.required }}
                </span>
            </div>

        </div>
        <hr/>
        <div class="row form-inline form-group">
            <div class="col-md-1">
                <label>{{$store.state.language.requestinfo_practiceloc}}</label>
            </div>
            <div class="col-md-3">
                <select
                    id="practiceloc"
                    name="practiceloc"
                    :class="errors.has('practiceloc') ? 'form-control full-width glowing-border':'form-control full-width'"
                    v-model.trim="requestinfo.practiceloc"
                    v-validate="{required: true, is_not : '-'}"
                    @change="onUpdated"
                >
                    <option value="-">-----</option>
                    <option :value="$store.state.language.requestinfo_other">{{$store.state.language.requestinfo_other}}</option>
                    <option :value="$store.state.language.requestinfo_cissso">{{$store.state.language.requestinfo_cissso}}</option>
                </select>
                <span v-show="errors.has('practiceloc')" class="error">
                    {{ $store.state.language.required }}
                </span>
            </div>
            <transition name="fade" mode="out-in" >
                <div v-if="requestinfo.practiceloc === $store.state.language.requestinfo_cissso" key="direction" class="col-md-8 form-inline form-group">
                    <div class="col-md-2">
                        <label>{{$store.state.language.requestinfo_direction}}</label>
                    </div>
                    <div class="col-md-4">
                        <select
                            id="direction"
                            name="direction"
                            :class="errors.has('direction') ? 'form-control full-width glowing-border':'form-control full-width'"
                            v-validate="require_direction"
                            @change="onUpdated"
                            v-model="requestinfo.direction"
                        >
                            <option value="-">-----</option>
                            <option :value="$store.state.language.dsapa">{{$store.state.language.dsapa}}</option>
                            <option :value="$store.state.language.dsmd">{{$store.state.language.dsmd}}</option>
                            <option :value="$store.state.language.dpj">{{$store.state.language.dpj}}</option>
                            <option :value="$store.state.language.dsi">{{$store.state.language.dsi}}</option>
                            <option :value="$store.state.language.dj">{{$store.state.language.dj}}</option>
                            <option :value="$store.state.language.didptsa">{{$store.state.language.didptsa}}</option>
                            <option :value="$store.state.language.derur">{{$store.state.language.derur}}</option>
                            <option :value="$store.state.language.dsmc">{{$store.state.language.dsmc}}</option>
                        </select>
                        <span v-show="errors.has('direction')" class="error">
                            {{ $store.state.language.required }}
                        </span>
                    </div>
                    <div v-if="requestinfo.direction === $store.state.language.dpj" class="col-md-6 form-inline form-group">
                        <div class="col-md-2">
                            <label>{{$store.state.language.requestinfo_sidpj}}</label>
                        </div>
                        <div class="col-md-10">
                            <select
                                id="dpj_service"
                                name="dpj_service"
                                :class="errors.has('dpj_service') ? 'form-control full-width glowing-border':'form-control full-width'"
                                v-validate="require_dpj"
                                @change="onUpdated"
                                v-model="requestinfo.sidpj"
                            >
                                <option value="-">-----</option>
                                <option :value="$store.state.language.dpj_rts">{{$store.state.language.dpj_rts}}</option>
                                <option :value="$store.state.language.dpj_eo">{{$store.state.language.dpj_eo}}</option>
                                <option :value="$store.state.language.dpj_app">{{$store.state.language.dpj_app}}</option>
                                <option :value="$store.state.language.dpj_ferm">{{$store.state.language.dpj_ferm}}</option>                                
                            </select>
                            <span v-show="errors.has('dpj_service')" class="error">
                                {{ $store.state.language.required }}
                            </span>
                        </div>
                    </div>
                </div>
                <div v-if="requestinfo.practiceloc === $store.state.language.requestinfo_other" key="other" class="col-md-8 form-inline form-group">
                    <div class="col-md-2">
                        <label>{{$store.state.language.details}}</label>
                    </div>
                    <div class="col-md-10">
                        <input
                            id="practiceloc_details"
                            name="practiceloc_details"
                            v-model.trim="requestinfo.practiceloc_details"
                            :class="errors.has('practiceloc_details') ? 'form-control full-width glowing-border':'form-control full-width'"
                            v-validate="require_loc_details"
                            @input="onUpdated"
                        >
                        <span v-show="errors.has('practiceloc_details')" class="error">
                            {{ $store.state.language.required }}
                        </span>
                    </div>
                </div>
            </transition>
        </div>
        <div class="row form-inline form-group">
            <div class="col-md-1">
                <label>{{$store.state.language.requestinfo_address}}</label>
            </div>
            <div class="col-md-7">
                <input
                    id="address"
                    name="address"
                    :class="errors.has('address') ? 'form-control full-width glowing-border':'form-control full-width'"
                    v-model.trim="requestinfo.address"
                    v-validate="{required: true}"
                    @input="onUpdated"
                />
                <span v-show="errors.has('address')" class="error">
                    {{ $store.state.language.required }}
                </span>
            </div>
            <div class="col-md-1">
                <label>{{$store.state.language.requestinfo_email}}</label>
            </div>
            <div class="col-md-3">
                <input
                    id="email"
                    name="email"
                    v-model.trim="requestinfo.email"
                    v-validate="{required:true, email:true}"
                    :class="errors.has('email') ? 'form-control full-width glowing-border':'form-control full-width'"
                    @input="onUpdated"
                />
                <span v-show="errors.has('email')" class="error">
                    {{ $store.state.language.required }}
                </span>
            </div>
        </div>
        <hr/>
        <div class="row form-inline form-group">
            <div class="col-md-1">
                <label>{{$store.state.language.requestinfo_phone}}</label>
            </div>
            <div class="col-md-3">
                <input
                    id="telephone"
                    name="telephone"
                    v-model="requestinfo.phone"
                    v-validate="{required: true}"
                    :class="errors.has('telephone') ? 'form-control full-width glowing-border':'form-control full-width'"
                    v-mask="'(###) ###-####'"
                    :placeholder="$store.state.language.contactinfo_phone_ph"
                    @input="onUpdated"
                />
                <span v-show="errors.has('telephone')" class="error">
                    {{ $store.state.language.required }}
                </span>
            </div>
            <div class="col-md-1">
                <label>{{$store.state.language.requestinfo_phoneext}}</label>
            </div>
            <div class="col-md-2">
                <input
                    id="ext"
                    name="ext"
                    v-model.trim="requestinfo.ext"
                    class="form-control full-width"
                    @input="onUpdated"
                    maxlength="6"
                />
            </div>
        </div>
        <hr/>
        <div class="row form-inline form-group">
            <div class="col-md-1">
                <label>{{$store.state.language.requestinfo_requestdate}}</label>
            </div>
            <div class="col-md-3">
                <el-date-picker
                    id="requestdate"
                    name="requestdate"
                    v-model="requestinfo.requestdate"
                    @change="UpdateRDate"
                    @blur="UpdateRDate"
                    v-validate="{required:true, is_not : null}"
                    :class="errors.has('requestdate') ? 'full-width glowing-border':'full-width'"
                    :picker-options="{disabledDate:datedisabled}"
                    value-format="yyyy-MM-dd"

                >
                </el-date-picker>
                <span v-show="errors.has('requestdate')" class="error">
                    {{ $store.state.language.required }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>


    export default {
        name: "RequestInfo",
        props : {
            value :{
                type: Object,
                default: () => {
                    return {
                        name : '',
                        surname : '',
                        jobtitle : '',
                        practiceloc : '-',
                        direction : '-',
                        practiceloc_details : '',
                        sidpj: '-',
                        address : '',
                        email  : '',
                        phone : '',
                        ext : '',
                        requestdate : new Date()
                    }
                }
            }
        },
        inject: {
            $validator: '$validator'
        },
        data(){
            return {
                requestinfo : this.value,
            }
        },
        computed :{
            require_dpj(){
                if(this.requestinfo.direction === this.$store.state.language.dpj)
                {
                    return {required : true, is_not : '-'}
                }
                return '';
            },
            require_direction(){
                if(this.requestinfo.practiceloc === this.$store.state.language.requestinfo_cissso)
                {
                    return {required : true, is_not : '-'}
                }

                return '';
            },
            require_loc_details(){
                if(this.requestinfo.practiceloc === this.$store.state.language.requestinfo_other)
                {
                    return {required : true}
                }
                return '';
            }
        },
        methods :{
            UpdateRDate(){
                if(this.requestinfo.requestdate === null)
                {
                    if(!this.errors.has('requestdate')) {
                        this.errors.add({
                            field: 'requestdate',
                            msg: 'invalid',
                            id : 9999
                        });
                    }
                }
                else
                {
                    if(this.errors.has('requestdate'))
                    {
                        this.errors.remove('requestdate');
                    }

                }
                this.onUpdated();
            },
            onUpdated(){
                this.$emit('input',this.requestinfo);
            },
            datedisabled(value){
                let currentdate = new Date();
                let lowerlimit = new Date(currentdate.getFullYear(),0,1);
                if(value <= currentdate && value >= lowerlimit)
                {
                    return false;
                }
                return true;
            }
        }
    }
</script>

<style scoped>

</style>
