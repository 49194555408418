/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue').default;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

import VueRouter from 'vue-router'
import ElementUi from 'element-ui'
import locale from 'element-ui/lib/locale/lang/fr'
import Vuex from 'vuex'
import router from './router'
import store from './store.js'
import VeeValidate from 'vee-validate'
import VueTheMask from 'vue-the-mask'

Vue.use(VueTheMask);
Vue.use(VueRouter);
Vue.use(ElementUi, { locale });
Vue.use(Vuex);
Vue.use(VeeValidate);

Vue.prototype.$config = config;
Vue.prototype.$lang_fr = lang_fr;
Vue.prototype.$lang_en = lang_en;

Vue.component('App', require('./components/Index.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
    router,
    store,
});
