import Vue from 'vue'
import Router from 'vue-router'
import Page0 from './components/Page0.vue';
import Page1 from './components/Page1.vue';
import Page2 from './components/Page2.vue';
import Page3 from './components/Page3.vue';
import Page4 from './components/Page4.vue';
import Page5 from './components/Page5.vue';
import Page6 from './components/Page6.vue';
import Page7 from './components/Page7.vue';


Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Page0',
      component: Page0,
      alias : '/page0'
    },
    {
      path: '/page1',
      name: 'Page1',
      component: Page1,
    },
    {
      path: '/page2',
      name: 'Page2',
      component: Page2
    },
    {
      path: '/page3',
      name: 'Page3',
      component: Page3
    },
    {
      path: '/page4',
      name: 'Page4',
      component: Page4
    },
    {
      path: '/page5',
      name: 'Page5',
      component: Page5
    },
    {
      path: '/page6',
      name: 'Page6',
      component: Page6
    },
    {
      path: '/page7',
      name: 'Page7',
      component: Page7
    }

  ]
})
