export const commonsettings = {
    data(){
        return {
            regex_phone : {},
            regex_postal_code : {},
            regex_nam : {},
            regex_exp : {},
            lang:{},
            config:{}
        }
    },
    created : function(){

        this.regex_phone = new RegExp(this.$config.regex.regex_phone);
        this.regex_postal_code = new RegExp(this.$config.regex.regex_postal_code);
        this.regex_nam = new RegExp(this.$config.regex.regex_nam);
        this.regex_exp = new RegExp(this.$config.regex.regex_exp);

    },
    methods:{
        NextPage(value){
       
            if(this.$validator.validateAll().then((result) => {
                if(result){
                    this.$store.commit('updatePage' + this.$store.state.currentstep,value);
                    this.$store.commit("nextPage");
                    this.$router.replace('/page' + this.$store.state.currentstep);
                }
                else{
                    this.$message({message: this.$store.state.language.error, type:'error', duration:5000 });
                    return false;
                }
            }));

        },
        PrevPage(value){
            this.$store.commit('updatePage' + this.$store.state.currentstep,value);
            this.$store.commit("prevPage");
            this.$router.replace( '/page' + this.$store.state.currentstep);
        },
        CalculateAge(userdob) {
            let dob = new Date(userdob === undefined ? this.$store.state.form.user_info.dob : userdob);
            let today = new Date();
            let age = today.getFullYear() - dob.getFullYear();

            // SI LE MOIS EST EGAL AU MOIS ACTUEL
            if(today.getMonth() === dob.getMonth())
            {
                //SI LA DATE < DATE ACTUELLE ENLEVER 1 AN
                if(today.getDate() < dob.getDate())
                {
                    age -= 1;
                }
            }

            if(age < 0)
                age = 0;
            return age;
        },
        FormatDate(date){
            let d = date === undefined ? new Date() : new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return [year, month, day].join('-');
        }



    }
};
