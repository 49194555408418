<template>
    <div class="content">
        <div class="card">
            <div class="card-header small_blue_heading">
                <small>
                    <b>{{ $store.state.language.section_a_title }}</b>
                </small>
            </div>
            <div class="card-body">
                <!-------------- SECTION NOM PRENOM DOB ----------------------->
                <app-userinfo v-model="form.user_info" @input="DoAgeCalculation"/>
                <hr/>
                <!-------------- SECTION NAM ---------------------------------->
                <app-naminfo v-model="form.nam_info"/>
                <hr/>
                <!-------------- SECTION SEX ET LANGUE ------------------------>
                <app-sexlanginfo v-model="form.sexlang_info" />
                <hr/>
                <!-------------- SECTION ADRESSE PERMANENTE ------------------->
                <app-addressinfo v-model="form.address_info"/>
                <hr/>
                <!-------------- SECTION NOM DES PARENTS ---------------------->
                <transition name="fade">
                    <div v-if="age < 18 && age !== -1">
                        <app-parentsinfo v-model="form.parents_info"/>
                        <hr/>
                    </div>
                </transition>
                <!-------------- SECTION ECOLE FREQUENTE ---------------------->
                <transition name="fade">
                    <div v-if="age < 18 && age !== -1">
                        <app-schoolinfo v-model="form.school_info"/>
                        <hr/>
                    </div>
                </transition>
                <!-------------- SECTION SANS DOMICILE FIXE ---------------------->
                <transition name="fade">
                    <div v-if="!form.address_info.sdf">
                        <app-servicesinfo v-model="form.services_info"/>
                        <hr/>
                    </div>
                </transition>
                <!-------------- SECTION ALLERGIES ------------------------>
                <app-allergiesinfo v-model="form.allergies_info"/>
                <hr/>
                <!-------------- SECTION CONTACT ------------------------>
                <div>
                    <app-contactinfo
                        id="contact"
                        name="contact"
                        v-model="contact"
                    />
                </div>
            </div>
        </div>
        <div class="bottom_buttons_div">
            <button class="btn btn-primary float-left" @click="PrevPage(form)">{{ $store.state.language.app_prev }}</button>
            <button class="btn btn-primary float-right" @click="NextPage(form)">{{ $store.state.language.app_next }}</button>
        </div>
    </div>
</template>

<script>
    import {commonsettings} from './mixins';
    import UserInfo from './Page1/UserInfo';
    import NamInfo from './Page1/NamInfo';
    import SexLangInfo from './Page1/SexLangInfo'
    import AddressInfo from './Page1/AddressInfo'
    import SchoolInfo from './Page1/SchoolInfo'
    import AllergiesInfo from './Page1/AllergiesInfo'
    import ContactInfo from './Page1/ContactInfo'
    import ServicesInfo from './Page1/ServicesInfo'
    import ParentsInfo from './Page1/ParentsInfo'

    export default {
        components : {
            "app-userinfo": UserInfo,
            "app-naminfo" : NamInfo,
            "app-sexlanginfo" : SexLangInfo,
            "app-addressinfo" : AddressInfo,
            "app-schoolinfo" : SchoolInfo,
            "app-allergiesinfo" : AllergiesInfo,
            "app-contactinfo" : ContactInfo,
            "app-servicesinfo" : ServicesInfo,
            "app-parentsinfo" : ParentsInfo,
        },
        mixins : [commonsettings],
        data(){
            return {
                age : -1,
                form : {
                    user_info : this.$store.state.form.user_info,
                    nam_info : this.$store.state.form.nam_info,
                    school_info : this.$store.state.form.school_info,
                    allergies_info : this.$store.state.form.allergies_info,
                    sexlang_info : this.$store.state.form.sexlang_info,
                    address_info : this.$store.state.form.address_info,
                    contact_info : this.$store.state.form.contact_info,
                    services_info : this.$store.state.form.services_info,
                    parents_info : this.$store.state.form.parents_info
                }
            }
        },
        methods :{
            DoAgeCalculation(){
                this.age = this.CalculateAge(this.form.user_info.dob);
            }
        },
        computed : {

            contact : {
                get: function() {
                    return JSON.stringify(this.form.contact_info);
                },
                set: function(val) {
                    let data = JSON.parse(val);
                    this.form.contact_info = data;
                }
            }
        },
        created(){
            if(this.$store.state.currentstep !== 1)
            {
                this.$router.push("/page" + this.$store.state.currentstep);
            }
            if(this.form.user_info.dob !== '')
            {
                this.DoAgeCalculation();
            }
        },

    }
</script>

<style>
    div.cond_section{
        background-color: #cacaca;
    }
    .verror{
        border-radius: 3px;
        border: 1px solid red;
        padding:10px;
    }
</style>
