<template>
    <div>
        <div class="row form-inline form-group">
            <div class="col-md-2">
                <label>{{$store.state.language.servicesinfo_perm}}</label>
            </div>
            <div class="col-md-2">
                <select
                        id="permanent"
                        name="permanent"
                        :class="errors.has('permanent') ? 'form-control full-width glowing-border':'form-control full-width'"
                        v-model="form.permanent"
                        v-validate="{required : true, is_not : '-'}"
                >
                    <option value="-">-----</option>
                    <option :value="$store.state.language.yes">{{ $store.state.language.yes }}</option>
                    <option :value="$store.state.language.no">{{ $store.state.language.no }}</option>
                </select>
                <span v-show="errors.has('permanent')" class="error">
                    {{ $store.state.language.required }}
                </span>
            </div>
            <div class="col-md-8"></div>
        </div>
        <transition name="fade">
            <div class="row form-inline form-group" v-if="form.permanent === $store.state.language.no">
                <div class="col-md-2">
                    <label>{{$store.state.language.details}}</label>
                </div>
                <div class="col-md-10">
                    <input
                        id="details"
                        name="details"
                        :class="errors.has('details') ? 'form-control full-width glowing-border':'form-control full-width'"
                        v-validate="require_details"
                        v-model.trim="form.details"
                        maxlength="500"
                    >
                    <span v-show="errors.has('details')" class="error">
                        {{ $store.state.language.required }}
                    </span>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import {commonsettings} from '../mixins';
    export default {
        name: "ServicesInfo",
        mixins : [commonsettings],
        props: {
            value:{
                type: Object,
                default: () => {
                    return {
                        permanent : '-',
                        details : ''
                    };
                }
            }
        },
        inject: {
            $validator: '$validator'
        },
        data() {
            return {
                form : this.value,
            }
        },
        computed:{
            require_details(){
                if(this.form.permanent === this.$store.state.language.no)
                {
                    return {required : true}
                }
                return '';
            }
        },
        methods: {
            onUpdated(){
                this.$emit('input',this.form);
            },
        }
    }
</script>

<style scoped>

</style>
