<template>
    <div>
        <div class="row form-inline form-group">
            <!---------------------- CHAMP NAM ------------------------------------------>
            <div class="col-md-2">
                <label for="nam">{{ $store.state.language.naminfo_nam }}</label>
            </div>
            <div class="col-md-4">
                <input
                    id="nam"
                    name="nam"
                    type="text"
                    v-model="form.nam"
                    :class="errors.has('nam') ? 'form-control full-width glowing-border':'form-control full-width'"
                    v-validate="validate_nam"
                    :disabled="no_nam"
                    :placeholder="$store.state.language.naminfo_nam_ph"
                    @input="onUpdated"
                    v-mask="'AAAA########'"
                >
                <span v-show="errors.has('nam')" class="error">
                    {{ $store.state.language.naminfo_nam_error }}
                </span>
            </div>
            <!---------------------- CHAMP DATE EXPIRATION ------------------------------>
            <div class="col-md-2">
                <label for="date_exp">{{ $store.state.language.naminfo_exp_date }}</label>
            </div>
            <div class="col-md-4">
                <el-date-picker
                    id="date_exp"
                    name="date_exp"
                    type="month"
                    v-model="form.expiration"
                    :class="errors.has('date_exp') ? 'full-width glowing-border':'full-width'"
                    v-validate="validate_nam_date"
                    :disabled="no_nam"
                    :placeholder="$store.state.language.naminfo_exp_date_ph"
                    format="yyyy/MM"
                    value-format="yyyy/MM"
                    @change="onUpdated"
                />
                <span v-show="errors.has('date_exp')" class="error" >
                     {{ $store.state.language.naminfo_dateexp_error }}
                </span>
            </div>
        </div>
        <transition name="fade">
            <!---------------------- CHAMP DETAILS --------------------------------------->
            <div class="row form-inline form-group" v-if="form.nam === ''">
                <div class="col-md-2">
                    <label for="noinfo">{{ $store.state.language.naminfo_noinfo }}</label>
                </div>
                <div class="col-md-10">
                    <input
                        id="noinfo"
                        name="noinfo"
                        type="text"
                        v-model.trim="form.details"
                        :class="errors.has('noinfo') ? 'form-control full-width glowing-border':'form-control full-width'"
                        v-validate="validate_noinfo"
                        :placeholder="$store.state.language.naminfo_noinfo_ph"
                        @keyup="toggle_nam"
                        @input="onUpdated"
                    >
                    <span v-show="errors.has('noinfo')" class="error">
                        {{ $store.state.language.required }}
                    </span>
                </div>

            </div>
        </transition>
    </div>
</template>

<script>
    import {commonsettings} from '../mixins';

    export default {
        mixins : [commonsettings],
        name: "NamInfo",
        props :{
            value :{
                type : Object,
                default : () => {
                    return {
                        nam:'',
                        expiration:'',
                        details:''
                    };
                }
            }
        },
        inject: {
            $validator: '$validator'
        },
        data(){
            return{
                no_nam : false,
                form: this.value,
            }
        },
        created(){
            this.toggle_nam();
        },
        computed : {

            //**************** VALIDATION DETAILS
            validate_noinfo(){
                if(this.form.details !== ''){
                    return '';
                }
                return { required : true };
            },
            //**************** VALIDATION NAM
            validate_nam(){
                if(this.no_nam == true){
                    return '';
                }
                return { regex : this.regex_nam};
            },
            //**************** VALIDATION EXPIRATION
            validate_nam_date(){
                if(this.form.details !== ''){
                    return '';
                }
                return { required : true };
            },
        },
        methods : {
            //**************** MASQUE LE CHAMPS DETAILS SI NAM
            toggle_nam(event){

                if(this.form.details !== '')
                {
                    this.no_nam = true;
                    this.form.nam = '';
                    this.form.expiration = '';
                    
                }
                else
                {
                    this.no_nam = false;
                    
                }
                this.onUpdated();
                this.$validator.reset();
            },
            onUpdated(){
                this.$emit('input',this.form);
            }
        }
    }
</script>

<style >
</style>
