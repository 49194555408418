<template>
    <div>
        <div class="row form-inline form-group">
            <div class="col-md-2">
                <label>{{$store.state.language.parentsinfo_parent1}}</label>
            </div>
            <div class="col-md-1">
                {{$store.state.language.parentsinfo_name}}
            </div>
            <div class="col-md-3">
                <input
                    id="parent1_name"
                    name="parent1_name"
                    :class="errors.has('parent1_name') ? 'form-control full-width glowing-border':'form-control full-width'"
                    v-model.trim="form.parent1_name"
                    v-validate="{required : true}"
                    @input="onUpdated"
                >
                <span v-show="errors.has('parent1_name')" class="error">
                    {{ $store.state.language.required }}
                </span>
            </div>
            <div class="col-md-1 pr-0">
                {{$store.state.language.parentsinfo_surname}}
            </div>
            <div class="col-md-3">
                <input
                    id="parent1_surname"
                    name="parent1_surname"
                    :class="errors.has('parent1_surname') ? 'form-control full-width glowing-border':'form-control full-width'"
                    v-model.trim="form.parent1_surname"
                    v-validate="{required : true}"
                    @input="onUpdated"
                >
                <span v-show="errors.has('parent1_surname')" class="error">
                    {{ $store.state.language.required }}
                </span>
            </div>
        </div>
        <div class="row form-inline form-group">
            <div class="col-md-2">
                <label>{{$store.state.language.parentsinfo_parent2}}</label>
            </div>
            <div class="col-md-1">
                {{$store.state.language.parentsinfo_name}}
            </div>
            <div class="col-md-3">
                <input
                    id="parent2_name"
                    name="parent2_name"
                    :class="errors.has('parent2_name') ? 'form-control full-width glowing-border':'form-control full-width'"
                    v-model.trim="form.parent2_name"
                    v-validate="require_parent2"
                    :disabled="form.parent2_inconnu"
                    @input="onUpdated"
                >
                <span v-show="errors.has('parent2_name')" class="error">
                    {{ $store.state.language.required }}
                </span>
            </div>
            <div class="col-md-1 pr-0">
                {{$store.state.language.parentsinfo_surname}}
            </div>
            <div class="col-md-3">
                <input
                    id="parent2_surname"
                    name="parent2_surname"
                    :class="errors.has('parent2_surname') ? 'form-control full-width glowing-border':'form-control full-width'"
                    v-model.trim="form.parent2_surname"
                    v-validate="require_parent2"
                    :disabled="form.parent2_inconnu"
                    @input="onUpdated"
                >
                <span v-show="errors.has('parent2_surname')" class="error">
                    {{ $store.state.language.required }}
                </span>
            </div>
            <div class="col-md-1 pr-0">
                {{$store.state.language.parentsinfo_unknown}}
            </div>
            <div class="col-md-1">
                <input
                    id="unknown2"
                    name="unknown2"
                    type="checkbox"
                    v-model="form.parent2_inconnu"
                    @click="onInconnu"
                >
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ParentsInfo",
        props:{
            value : {
                type: Object,
                default : () => {
                    return {
                        parent1_name: '',
                        parent1_surname :'',
                        parent2_name: '',
                        parent2_surname : '',
                        parent2_inconnu: '',
                    };
                }
            },
        },
        inject: {
            $validator: '$validator'
        },
        data(){
            return {
                unknown : false,
                form : this.value
            }
        },
        computed :{
            require_parent2(){
                if(this.form.parent2_inconnu)
                {

                    return ''
                }
                this.unknown = false;
                return {required : true};
            }
        },
        methods : {
            onUpdated() {
                this.$emit('input', this.form);
            },
            onInconnu(){
                if(this.form.parent2_inconnu)
                {
                    this.form.parent2_name = '';
                    this.form.parent2_surname = '';
                }
                this.onUpdated();
            }
        }
    }
</script>

<style scoped>

</style>
