<template>
    <div>
        <div class="row form-inline form-group">
            <div class="col-md-12">
                <label>{{ $store.state.language.otherservicesinfo_other_services }}</label>
            </div>
        </div>
        <div class="row form-inline form-group">
            <div class="col-md-4">
                <select
                    id="other_services"
                    name="other_services"
                    :class="errors.has('other_services') ? 'form-control full-width glowing-border':'form-control full-width'"
                    v-model="other_services.other_services"
                    v-validate="{required : true, is_not: '-'}"
                    @change="onUpdated"
                >
                    <option value="-">-----</option>
                    <option :value="$store.state.language.yes">{{ $store.state.language.yes }}</option>
                    <option :value="$store.state.language.no">{{ $store.state.language.no }}</option>
                </select>
                <span v-show="errors.has('other_services')" class="error">
                    {{ $store.state.language.required }}
                </span>
            </div>
            <div class="col-md-8">

            </div>
        </div>
        <transition name="fade">
            <div v-if="other_services.other_services === $store.state.language.yes">
                <div class="row form-inline form-group">
                    <div class="col-md-12">
                        <label>{{ $store.state.language.details }}</label>
                    </div>
                </div>
                <div class="row form-inline form-group">
                    <div class="col-md-12">
                        <textarea type="text"
                            name="other_services_details"
                            id="other_services_details"
                            rows="6"
                            :class="errors.has('other_services_details') ? 'form-control full-width glowing-border':'form-control full-width'"
                            v-validate="require_other_details"
                            v-model.trim="other_services.other_services_details"
                            @input="onUpdated"
                            maxlength="600"
                        ></textarea>
                        <span v-show="errors.has('other_services_details')" class="error">
                             {{ $store.state.language.required }}
                        </span>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name: "otherservicesinfo",
        props : {
            value :{
                type: Object,
                default: () => {
                    return {
                        other_services : '-',
                        other_services_details : ''
                    }
                }
            }
        },
        inject: {
            $validator: '$validator'
        },
        data(){
            return {
                other_services : this.value
            }
        },
        computed :{
            require_other_details(){
                if(this.other_services.other_services !== '-')
                {
                    return { required : true }
                }
                return '';
            }
        },
        methods :{
            onUpdated(){
                this.$emit('input',this.other_services);
            }
        }
    }
</script>

<style scoped>

</style>
