<template>
    <div class="content">
        <h3 class="text-center">{{$store.state.language.accueil_title}}</h3>
        <p v-html="$store.state.language.accueil_para_1"></p>
        <div class="card">
           <div class="card-header small_blue_heading">
               <small><b>{{$store.state.language.accueil_section_title}}</b></small>
           </div>
           <div class="card-body">
               <ol>
                   <li>{{$store.state.language.accueil_b1}}</li>
                   <li><p v-html="$store.state.language.accueil_b2"></p></li>
                   <li>{{$store.state.language.accueil_b3_p1}}<br/>
                       <i class="fas fa-question-circle"></i> {{$store.state.language.accueil_b3_p2}}
                   </li>
                   <li>{{$store.state.language.accueil_b4}}</li>
                   <li><p v-html="$store.state.language.accueil_b5"></p></li>
               </ol>
               <app-destinfo v-model="form.email_info"/>
           </div>
        </div>
        <hr/>
        <div class="bottom_buttons_div">
            <button class="btn btn-primary float-right" @click="NextPage(form)">{{ $store.state.language.app_next }}</button>
        </div>
    </div>
</template>

<script>
    import {commonsettings} from './mixins'
    import DestinationInfo from './Page0/DestinationInfo'

    export default {
        components :{
            "app-destinfo" : DestinationInfo,
        },
        mixins : [commonsettings],
        data() {
            return {
                form : {
                    email_info : this.$store.state.form.email,
                }
            }
        },
        created(){
            if(this.$store.state.currentstep !== 0)
            {
                this.$router.push("/page" + this.$store.state.currentstep);
            }
        }
    }
</script>

<style >
    ol li {
        margin-bottom:20px;
    }
</style>
