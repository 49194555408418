<template>
    <div>
        <p>{{$store.state.language.destinationinfo_title}}</p>
        <select
            id="guichet"
            name="guichet"
            v-model="email"
            :class="errors.has('guichet') ? 'full-width form-control glowing-border':'form-control full-width'"
            v-validate="{ is_not : -1}"
            @change="onUpdated"
        >
            <option :value="-1">{{$store.state.language.destinationinfo_select}}</option>
            <option v-for="ga in $config.listemails" :value="ga.id" :key="ga.id">{{ ga.name }}</option>
        </select>
        <span v-show="errors.has('guichet')" class="error">
            {{ $store.state.language.required }}
        </span>
    </div>
</template>

<script>
    export default {
        name: "DestinationInfo",
        props : {
            value :{
                type: Number,
                default: ()=>{
                    return -1;
                }
            }
        },
        inject: {
            $validator: '$validator'
        },
        data(){
            return {
                email : this.value,
            }
        },
        computed :{

        },
        methods: {
            onUpdated(){
                this.$emit('input', this.email);
            }
        }
    }
</script>

<style scoped>

</style>
