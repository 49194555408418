<template>
    <div>
        <div class="row form-inline form-group">
            <!---------------------------- CHAMP SEX ----------------------------------------->
            <div class="col-md-2">
                <label for="sex">
                    {{ $store.state.language.sexlanginfo_sex }}
                </label>
            </div>
            <div class="col-md-4 right_border">
                <select
                    id="sex"
                    name="sex"
                    v-model="form.sex"
                    :class="errors.has('sex') ? 'form-control full-width glowing-border':'form-control full-width'"
                    v-validate="{required: true, is_not:'-'}"
                    @change="onUpdated"
                >
                    <option value="-">-----</option>
                    <option :value="$store.state.language.sexlanginfo_sex_male">{{ $store.state.language.sexlanginfo_sex_male }}</option>
                    <option :value="$store.state.language.sexlanginfo_sex_female">{{ $store.state.language.sexlanginfo_sex_female }}</option>
                    <option :value="$store.state.language.sexlanginfo_sex_other">{{ $store.state.language.sexlanginfo_sex_other }}</option>
                </select>
                <span v-show="errors.has('sex')" class="error">
                    {{ $store.state.language.required }}
                </span>
            </div>
            <!---------------------------- CHAMP LANGAGE ----------------------------------------->
            <div class="col-md-2">
                <label for="language">
                    {{ $store.state.language.sexlanginfo_language }}
                </label>
            </div>
            <div class="col-md-4">
                <select
                    id="language"
                    name="language"
                    v-model="form.language"
                    :class="errors.has('language') ? 'form-control full-width glowing-border':'form-control full-width'"
                    v-validate="{required:true, is_not:'-'}"
                    @change="onUpdated"
                >
                    <option value="-">-----</option>
                    <option :value="$store.state.language.sexlanginfo_language_fr">{{ $store.state.language.sexlanginfo_language_fr }}</option>
                    <option :value="$store.state.language.sexlanginfo_language_en">{{ $store.state.language.sexlanginfo_language_en }}</option>
                    <option :value="$store.state.language.sexlanginfo_language_oth">{{ $store.state.language.sexlanginfo_language_oth }}</option>
                </select>
                <span v-show="errors.has('language')" class="error">
                    {{ $store.state.language.required }}
                </span>
            </div>

        </div>
        <transition name="fade">
            <div class="row form-inline form-group" v-if="form.language === $store.state.language.sexlanginfo_language_oth ">
                <div class="col-md-1"></div>
                <div class="col-md-3"></div>
                <div class="col-md-2"></div>
                <div class="col-md-2">
                    <label for="lang_details">
                        {{ $store.state.language.sexlanginfo_language_pre }}
                    </label>
                </div>
                <div class="col-md-4">
                    <input
                        id="lang_details"
                        name="lang_details"
                        type="text"
                        v-model.trim="form.details"
                        :class="errors.has('lang_details') ? 'form-control full-width glowing-border':'form-control full-width'"
                        v-validate="require_lang_precision"
                        @input="onUpdated"
                    >
                    <span v-show="errors.has('lang_details')" class="error">
                        {{ $store.state.language.required }}
                    </span>
                </div>
            </div>
        </transition>

    </div>
</template>

<script>
    export default {
        name: "SexLang",
        props:{
            value :{
                type: Object,
                default: () => {
                    return {
                        sex: '-',
                        language: '-',
                        details :''
                    };
                }
            }
        },
        inject: {
            $validator: '$validator'
        },
        data(){
           return {
               form : this.value
           }
        },
        computed: {
            require_lang_precision(){
                if(this.form.language !== this.$store.state.language.sexlanginfo_language_oth)
                {
                    return '';
                }
                return {required: true}
            },
        },
        methods: {
            onUpdated(){
                this.$emit('input', this.form );
            }
        },
    }
</script>

<style scoped>

</style>
