<template>
    <div class="content">
        <div class="card">
            <div class="card-heading small_blue_heading">
                <small>
                    <b>{{ $store.state.language.section_b_title }}</b>
                </small>
            </div>
            <div class="card-body">
                <!-- BESOINS -->
                <app-needsinfo v-model="form.needs"/>
                <hr/>
                <!-- DÉCLENCHEURS -->
                <app-triggersinfo v-model="form.triggers"/>
                <hr/>
                <!-- PROBLEMATIQUE -->
                <app-problemsinfo v-model="form.problems"/>
                <hr/>
                <!-- FACTEURS DE RISQUES -->
                <app-risksinfo v-model="form.risks"/>
                <hr/>
                <!-- DIAGNOSTIQUE -->
                <app-diaginfo v-model="form.diags"/>
                <hr/>
                <!-- RECOMMENDATIONS -->
                <app-recominfo v-model="form.recom"  />
            </div>
        </div>
        <div class="bottom_buttons_div">
            <button class="btn btn-primary float-left" @click="PrevPage(form)">{{ $store.state.language.app_prev }}</button>
            <button class="btn btn-primary float-right" @click="NextPage(form)">{{ $store.state.language.app_next }}</button>
        </div>
    </div>
</template>

<script>
    import {commonsettings} from './mixins';
    import NeedsInfo from './Page2/NeedsInfo'
    import TriggersInfo from './Page2/TriggersInfo'
    import ProblemsInfo from './Page2/ProblemsInfo'
    import RisksInfo from './Page2/RisksInfo'
    import DiagnosticsInfo from './Page2/DiagnosticsInfo'
    import RecommendationInfo from './Page2/RecommendationsInfo'

    export default {
        components : {
            "app-needsinfo" : NeedsInfo,
            "app-triggersinfo" : TriggersInfo,
            "app-problemsinfo" : ProblemsInfo,
            "app-risksinfo" : RisksInfo,
            "app-diaginfo" : DiagnosticsInfo,
            "app-recominfo" : RecommendationInfo
        },
        mixins : [commonsettings],
        data() {
            return {
                form : {
                    needs : this.$store.state.form.needs,
                    triggers : this.$store.state.form.triggers,
                    problems : this.$store.state.form.problems,
                    risks : this.$store.state.form.risks,
                    diags : this.$store.state.form.diags,
                    recom : this.$store.state.form.recom,
                }
            }
        },
        created(){
            if(this.$store.state.currentstep !== 2)
            {
                this.$router.push("/page" + this.$store.state.currentstep);
            }
        }
    }
</script>

<style>

</style>
