<template>
    <div>
        <transition name="fade">
            <div v-if="form.sdf === false">
                <div class="row form-inline form-group">
                    <!-------------------------- CHAMP ADRESSE ----------------------------->
                    <div class="col-md-2">
                        <label for="address">
                            {{ $store.state.language.addressinfo_address }}
                        </label>
                    </div>
                    <div class="col-md-10">
                        <input
                            id="address"
                            name="address"
                            type="text"
                            v-model.trim="form.address"
                            :class="errors.has('address') ? 'form-control full-width glowing-border':'form-control full-width'"
                            v-validate="require_address"
                            :placeholder="$store.state.language.addressinfo_address_ph"
                            :disabled="form.sdf"
                            @input="onUpdated"
                        >
                        <span v-show="errors.has('address')" class="error">
                            {{ $store.state.language.required }}
                        </span>
                    </div>
                </div>
                <div class="row form-inline form-group">
                    <!-------------------------- CHAMP VILLE ----------------------------->
                    <div class="col-md-2">
                        <label for="city">
                            {{ $store.state.language.addressinfo_city }}
                        </label>
                    </div>
                    <div class="col-md-4">
                        <input
                            id="city"
                            name="city"
                            type="text"
                            v-model.trim="form.city"
                            :class="errors.has('city') ? 'form-control full-width glowing-border':'form-control full-width'"
                            v-validate="require_city"
                            :disabled="form.sdf"
                            @input="onUpdated"
                        >
                        <span v-show="errors.has('city')" class="error">
                            {{ $store.state.language.required }}
                        </span>
                    </div>
                    <!-------------------------- CHAMP CODE POSTAL ----------------------->
                    <div class="col-md-2">
                        <label for="postal_code">
                            {{ $store.state.language.addressinfo_postal_code }}
                        </label>
                    </div>
                    <div class="col-md-4">
                        <input
                            id="postal_code"
                            name="postal_code"
                            type="text"
                            v-model="form.postal_code"
                            :class="errors.has('postal_code') ? 'form-control full-width glowing-border':'form-control full-width'"
                            v-validate="require_postal"
                            :disabled="form.sdf"
                            :placeholder="$store.state.language.addressinfo_postal_code_ph"
                            v-mask="'A#A #A#'"
                            @input="onUpdated"
                        >
                        <span v-show="errors.has('postal_code')" class="error">
                            {{ $store.state.language.addressinfo_postal_code_error }}
                        </span>
                    </div>
                </div>
            </div>
        </transition>
        <div class="row form-inline form-group">
            <div class="col-md-2">
                <label for="sdf" >{{$store.state.language.addressinfo_sdf}}</label>
            </div>
            <div class="col-md-10">
                <input
                    id="sdf"
                    name="sdf"
                    type="checkbox"
                    v-model="form.sdf"
                    class="form-control"
                    @input="onSdf"
                >
            </div>
        </div>
    </div>
</template>

<script>
    import {commonsettings} from '../mixins';
    export default {
        mixins : [commonsettings],
        name: "AddressInfo",
        props :{
            value:{
                type: Object,
                default: () => {
                    return {
                        address:'',
                        postal_code:'',
                        city: '',
                        sdf:''};
                }
            }
        },
        inject: {
            $validator: '$validator'
        },
        data(){
            return {
                form: this.value
            }
        },
        computed : {
            require_address(){
                if(this.form.sdf)
                {

                    return '';
                }
                return {required: true};
            },
            require_city(){
                if(this.require_address === '')
                {
                    return '';
                }
                return {required:true}
            },
            require_postal(){
                if(this.require_address === '')
                {
                    return '';
                }
                return { regex: this.regex_postal_code}
            },
        },
        methods: {
            onUpdated(){
                this.$emit('input',this.form);
            },
            onSdf(){
                this.form.address = '';
                this.form.city = '';
                this.form.postal_code = '';
                this.onUpdated();
            }
        }
    }
</script>

<style scoped>

</style>