<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <label >{{ $store.state.language.contactinfo_method }}</label>
            </div>
        </div>

        <b class="red">{{$store.state.language.contactinfo_notice}}</b><br/><br/>

        <div class="row">
            <div class="col-md-2">{{$store.state.language.contactinfo_tel}}</div>
            <div class="col-md-3">{{$store.state.language.contactinfo_number}}</div>
            <div class="col-md-2">{{$store.state.language.contactinfo_voicemail}}</div>
            <div class="col-md-3">{{$store.state.language.contactinfo_details}}</div>
            <div class="col-md-2">Action</div>
        </div>

        <div class="row form-inline form-group">
            <div class="col-md-2">
                <select
                    id="type"
                    name="type"
                    v-model="newct.type"
                    :class="errors.has('type') ? 'form-control full-width glowing-border':'form-control full-width'"
                    v-validate="{required : true, is_not : '-'}"
                    @change="changeAddContactButton"
                >
                    <option value="-">-----</option>
                    <option :value="$store.state.language.contactinfo_teltype_principal">{{$store.state.language.contactinfo_teltype_principal}}</option>
                    <option :value="$store.state.language.contactinfo_teltype_work">{{$store.state.language.contactinfo_teltype_work}}</option>
                    <option :value="$store.state.language.contactinfo_teltype_cellphone">{{$store.state.language.contactinfo_teltype_cellphone}}</option>
                    <option :value="$store.state.language.contactinfo_teltype_other">{{$store.state.language.contactinfo_teltype_other}}</option>
                </select>
                <span v-show="errors.has('type')" class="error">
                    {{ $store.state.language.required }}
                </span>
            </div>
            <div class="col-md-3">
                <input
                    id="number"
                    name="number"
                    :class="errors.has('number') ? 'form-control full-width glowing-border':'form-control full-width'"
                    v-model="newct.number"
                    :placeholder="$store.state.language.contactinfo_phone_ph"
                    v-validate="{required:true,regex: regex_phone }"
                    v-mask="'(###) ###-####'"
                    @change="changeAddContactButton"
                />
                <span v-show="errors.has('number')" class="error">
                    {{ $store.state.language.required }}
                </span>
            </div>
            <div class="col-md-2">
                <select
                    id="voicemail"
                    name="voicemail"
                    :class="errors.has('voicemail') ? 'form-control full-width glowing-border':'form-control full-width'"
                    v-model="newct.msg"
                    v-validate="{required: true, is_not:'-'}"
                    @change="changeAddContactButton"
                >
                    <option value="-">-----</option>
                    <option :value="$store.state.language.yes">{{ $store.state.language.yes }}</option>
                    <option :value="$store.state.language.no">{{ $store.state.language.no }}</option>
                </select>
                <span v-show="errors.has('voicemail')" class="error">
                    {{ $store.state.language.required }}
                </span>
            </div>
            <div class="col-md-3">
                <input
                    id="details"
                    name="details"
                    class="form-control full-width"
                    v-model.trim="newct.details"
                    placeholder="commentaire"
                    maxlength="275"
                >
            </div>
            <div class="col-md-2">
                <button :class="addcontactclass" @click="addContact" :disabled="enabledaddcontact">
                    Ajouter
                </button>
            </div>

        </div>
        <div class="row form-inline form-group" v-for="(ct,no) in templist" v-bind:key="no">
            <div class="col-md-2">{{ct.type}}</div>
            <div class="col-md-3">{{ct.number}}</div>
            <div class="col-md-2">{{ct.msg}}</div>
            <div class="col-md-3">{{ct.details}}</div>
            <div class="col-md-2">
                <button class="btn btn-info mr-1" @click="editContact(ct)" title="Modifier">
                    <i class="fas fa-edit"></i>
                </button>
                <button class="btn btn-danger" @click="delContact(ct)" title="Retirer">
                    <i class="fas fa-trash"></i>
                </button>
            </div>
        </div>

        <div class="row form-inline form-group" v-if="templist.length === 0">
            <div class="col-md-12">
                <div class="nocontact">{{$store.state.language.contactinfo_nocontact}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import {commonsettings} from '../mixins';

    export default {
        mixins : [commonsettings],
        name: "Contact",
        props: {
            value:{
                type: String,
                default: () => {
                    return [];
                }
            }
        },
        data() {
            return {
                newct: {
                    type: '-',
                    number : '',
                    msg : '-',
                    details :''
                },
                templist : [],
                listct : this.value,
                enabledaddcontact : true,
                addcontactclass : 'btn btn-light'
            }
        },
        computed:{
        },
        methods:{
            changeAddContactButton(){
                if(this.newct.type != "-" && this.newct.number != '' && this.newct.msg != '-')
                {
                    this.enabledaddcontact = false;
                    this.addcontactclass = 'btn btn-success'
                }
                else
                {
                    this.enabledaddcontact = true;
                    this.addcontactclass = 'btn btn-light'
                }
            },
            delContact(ct){
                this.templist = this.templist.filter(item => item !== ct);
                this.onUpdated();
            },
            editContact(ct){
                this.delContact(ct);
                this.newct.type = ct.type;
                this.newct.number = ct.number;
                this.newct.msg = ct.msg;
                this.newct.details = ct.details;
                this.onUpdated();
            },
            addContact()
            {
                this.$validator.validateAll().then(result => {
                   if(result){
                       if(this.templist.length < 4) {
                           this.templist.push({
                               type: this.newct.type,
                               number: this.newct.number,
                               msg: this.newct.msg,
                               details: this.newct.details
                           });
                           this.newct.type = '-';
                           this.newct.number = '';
                           this.newct.msg = '-';
                           this.newct.details = '';

                           this.$nextTick().then(() => {
                               this.$validator.reset();
                               this.errors.clear();
                           });
                           this.onUpdated();
                       }
                       else {
                           this.$message({message: this.$store.state.language.contactinfo_limit, type:'error', duration:5000 });
                       }
                   }
                   else {
                       this.$message({message: this.$store.state.language.error, type:'error', duration:5000 });
                   }
                });
            },
            onUpdated(){
                let json = JSON.stringify(this.templist);
                this.$emit('input', json);
            },

        },
        created(){
            this.templist = JSON.parse(this.value);

        }
    }
</script>

<style scoped>
    .nocontact{
        border: 1px dashed grey;
        border-radius: 3px;
        padding:5px;
        font-weight: bold;
        text-align: center;
    }
</style>
