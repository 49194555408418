<template>
    <div>
        <div class="row form-inline form-group">
            <div class="col-md-12">
                <label for="needs">{{ $store.state.language.needsinfo_user_needs }}</label>
                <el-tooltip>
                    <div slot="content">
                        <span v-html="$store.state.language.needsinfo_user_needs_tooltip"></span>
                    </div>
                    <i class="fas fa-question-circle"></i>
                </el-tooltip>
            </div>
        </div>
        <div class="row form-inline form-group">
            <div class="col-md-12">
                <textarea
                    id="needs"
                    name="needs"
                    v-model.trim="needs"
                    :class="errors.has('needs') ? 'form-control full-width glowing-border':'form-control full-width'"
                    v-validate="{required : true}"
                    rows="4"
                    @input="onUpdated"
                    maxlength="600"
                ></textarea>
                <span v-show="errors.has('needs')" class="error">
                    {{ $store.state.language.required }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "NeedsInfo",
        props : {
            value :{
                type: String,
                default: ""
            }
        },
        inject: {
            $validator: '$validator'
        },
        data(){
            return {
                needs : this.value
            }
        },
        computed :{

        },
        methods :{
            onUpdated(){
                this.$emit('input',this.needs);
            }
        }
    }
</script>

<style scoped>

</style>