<template>
    <div>
        <div class="row form-inline form-group">
            <div class="col-md-12">
                <label>{{ $store.state.language.diagnosticsinfo_diag }}</label>
            </div>
        </div>
        <div class="row form-inline form-group">
            <div class="col-md-12">
                <select
                    id="diag"
                    name="diag"
                    :class="errors.has('diag') ? 'form-control full-width glowing-border':'form-control full-width'"
                    v-model="diagnostic.status"
                    v-validate="{required : true, is_not: '-'}"
                    @change="onUpdated"
                >
                    <option value="-">-----</option>
                    <option :value="$store.state.language.diagnosticsinfo_diag_known">{{ $store.state.language.diagnosticsinfo_diag_known }}</option>
                    <option :value="$store.state.language.diagnosticsinfo_diag_unknown">{{ $store.state.language.diagnosticsinfo_diag_unknown}}</option>
                    <option :value="$store.state.language.diagnosticsinfo_diag_waiting">{{ $store.state.language.diagnosticsinfo_diag_waiting}}</option>
                </select>
                <span v-show="errors.has('diag')" class="error">
                    {{ $store.state.language.required }}
                </span>
            </div>
        </div>
        <transition name="fade">
            <div v-if="diagnostic.status === $store.state.language.diagnosticsinfo_diag_known ||
                       diagnostic.status === $store.state.language.diagnosticsinfo_diag_waiting">
                <div class="row form-inline form-group">
                    <div class="col-md-12">
                        <label for="diagnostics">{{ $store.state.language.diagnosticsinfo_diag_diag }}</label>
                    </div>
                </div>
                <div class="row form-inline form-group">
                    <div class="col-md-12">
                        <textarea
                            id="diagnostics"
                            name="diagnostics"
                            :class="errors.has('diagnostics') ? 'form-control full-width glowing-border':'form-control full-width'"
                            v-validate="require_diagnostics"
                            v-model.trim="diagnostic.diagnostic"
                            @input="onUpdated"
                            maxlength="600"
                        >
                        </textarea>
                        <span v-show="errors.has('diagnostics')" class="error">
                            {{ $store.state.language.required }}
                        </span>
                    </div>
                </div>
            </div>
        </transition>
        <span class="diag_warn"> {{ $store.state.language.diagnosticsinfo_warning}}</span>
    </div>
</template>

<script>
    export default {
        name: "DiagnosticsInfo",
        props : {
            value :{
                type: Object,
                default: () =>{
                    return { status: '-', diagnostic: ''}
                }
            }
        },
        inject: {
            $validator: '$validator'
        },
        data(){
            return {
                diagnostic : this.value
            }
        },
        computed :{
            require_diagnostics(){
                if(this.diagnostic.status !== this.$store.state.language.diagnosticsinfo_diag_known)
                {
                    return '';
                }
                return {required : true};
            },
        },
        methods :{
            onUpdated(){
                this.$emit('input',this.diagnostic);
            }
        }
    }

</script>

<style scoped>
    .diag_warn{
        background-color: yellow;
        font-weight: bold;
    }
</style>
