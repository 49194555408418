<template>
    <div>
        <div class="card">
            <div class="card-heading small_blue_heading">
                <small>
                    <b>{{ $store.state.language.section_e_title }}</b>
                </small>
            </div>
            <div class="card-body">
                <app-consentinfo v-model="form.consent"></app-consentinfo>
            </div>
        </div>
        <div class="bottom_buttons_div">
            <button class="btn btn-primary float-left" @click="PrevPage(form)">{{ $store.state.language.app_prev }}</button>
            <button class="btn btn-primary float-right" @click="NextPage(form)">{{ $store.state.language.app_next }}</button>
        </div>
    </div>
</template>

<script>
    import {commonsettings} from './mixins'
    import ConscentInfo from './Page5/ConsentInfo'

    export default {
        data(){
            return {
                form : {
                    consent: this.$store.state.form.consent
                }
            }
        },
        components: {
            "app-consentinfo": ConscentInfo
        },
        mixins : [commonsettings],
        created(){
            if(this.$store.state.currentstep !== 5)
            {
                console.log("/page" + this.$store.state.currentstep);
                this.$router.push("/page" + this.$store.state.currentstep);
            }
        }
    }
</script>

<style>

</style>
