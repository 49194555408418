import Vue from 'vue';
import Vuex from 'vuex';
import 'es6-promise/auto'

Vue.use(Vuex);

const getDefaultState = (lang) => {
    return {
        currentstep: 0,
        language : lang,
        form :{
            email : -1,
            user_info:{
                name : '',
                surname : '',
                dob: '' ,
                filenbr: '',
            },
            services_info : {
                permanent : '-',
                details : ''
            },
            nam_info : {
                nam : '',
                expiration : '',
                details : '',
            },
            sexlang_info : {
                sex : '-',
                language : '-',
                details :'',
            },
            address_info :{
                address: '',
                city: '',
                postal_code : '',
                sdf: false
            },
            parents_info:{
                parent1_name: '',
                parent1_surname: '',
                parent2_name: '',
                parent2_surname: '',
                parent2_inconnu: false
            },
            school_info:{
                frequent: '-',
                details: '',
            },
            allergies_info : {
                allergies : '-',
                details : ''
            },
            contact_info : [],
            needs : '',
            triggers : '',
            problems : '',
            risks : '',
            diags :{
                status: '-',
                diagnostic: '',
            },
            recom : '',
            suicidal : {
                suicidal : '-',
                details : '',
                frequency : '-'
            },
            homicidal :{
                homicidal : '-',
                details : ''
            },           
            services : {
                doctor : {
                    doctor : '-',
                    doctor_details : ''
                },
                gmf : {
                    gmf: '-',
                    gmf_details: '',
                },
                services :{
                    services : '-',
                    services_details: '',
                },
                interventions : {
                    intervention : '-',
                    interventions_details : '',
                },                 
                other_services : {
                    other_services : '-',
                    other_services_details : ''
                }
            },
            consent : {
                userless14 : '-',
                userless14_details : '',
                userplus14 : '-',
                minor1417: '-',
                minor1417contact: '-',
                minor1417contactname: '',
                minor1417phone:''
            },
            requestinfo : {
                name : '',
                surname : '',
                jobtitle : '',
                practiceloc : '-',
                practiceloc_details: '',
                address : '',
                email  : '',
                phone : '',
                ext : '',
                requestdate : new Date(),
                sidpj: '-',
                direction: '-'
            }
        }        
    }
}

const state = getDefaultState();

export default new Vuex.Store({
    state,
    mutations:{
        updateLanguage(state,value) {
            state.language = value;
        },
        updatePage0(state,value) {
            state.form.email = value.email_info;
        },
        updatePage1(state,value){
            state.form.user_info = value.user_info;
            state.form.nam_info = value.nam_info;
            state.form.sexlang_info = value.sexlang_info;
            state.form.address_info = value.address_info;
            state.form.allergies_info = value.allergies_info;
            state.form.school_info = value.school_info;
            state.form.contact_info = value.contact_info;
            state.form.services_info = value.services_info;
            state.form.parents_info = value.parents_info;
        },
        updatePage2(state,value){
            state.form.needs = value.needs;
            state.form.triggers = value.triggers;
            state.form.problems = value.problems;
            state.form.risks = value.risks;
            state.form.diags = value.diags;
            state.form.recom = value.recom;
        },
        updatePage3(state,value){
            state.form.suicidal = value.suicidal;
            state.form.homicidal = value.homicidal;
        },
        updatePage4(state,value){
            state.form.services.doctor = value.doctor;
            state.form.services.gmf = value.gmf;
            state.form.services.services = value.services;
            state.form.services.other_services = value.other_services;
            state.form.services.interventions = value.interventions;
        },
        updatePage5(state,value){
            state.form.consent = value.consent;
        },
        updatePage6(state,value){
            state.form.requestinfo = value.requestinfo;
        },
        updateCreateDate(state,value){
            state.form.requestinfo.requestdate = value;
        },
        nextPage(state){
            state.currentstep++;
        },
        prevPage(state){
            state.currentstep--;
        },
        resetform(state,lang){
            Object.assign(state, getDefaultState(lang));
        }

    }
})
