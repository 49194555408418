<template>
    <div class="row form-inline form-group">
        <div class="col-md-2">
            <label for="allergies">{{ $store.state.language.allergiesinfo_allergies }}</label>
        </div>
        <div class="col-md-2">
            <select
                id="allergies"
                name="allergies"
                v-model="form.allergies"
                :class="errors.has('allergies') ? 'form-control glowing-border':'form-control'"
                v-validate="{required:true, is_not:'-'}"
                @change="onUpdated"
            >
                <option value="-">------</option>
                <option :value="$store.state.language.yes">{{ $store.state.language.yes }}</option>
                <option :value="$store.state.language.no">{{ $store.state.language.no }}</option>
            </select>
            <span v-show="errors.has('allergies')" class="error">
                {{ $store.state.language.required }}
            </span>
        </div>
        <transition name="fade">
            <div class="col-md-2" v-if="form.allergies === $store.state.language.yes">
                <label for="allergies_details">{{ $store.state.language.details }}</label>
            </div>
        </transition>
        <transition name="fade">
            <div class="col-md-6" v-if="form.allergies === $store.state.language.yes">
                <input
                    id="allergies_details"
                    name="allergies_details"
                    type="text"
                    v-model.trim="form.details"
                    :class="errors.has('allergies_details') ? 'form-control full-width glowing-border':'form-control full-width'"
                    v-validate="require_allergies_precision"
                    @input="onUpdated"
                >
                <span v-show="errors.has('allergies_details')" class="error">
                     {{ $store.state.language.required }}
                </span>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name: "Allergies",
        props:{
            updated :{
                type: Function
            },
            value :{
                type : Object,
                default: () => {
                    return {
                        allergies : false,
                        details : ''
                    };
                }
            }
        },
        inject: {
            $validator: '$validator'
        },
        data(){
            return {
                form : this.value,
            }
        },
        computed :{

            require_allergies_precision(){
                if(this.form.allergies === this.$store.state.language.no){
                    return '';
                }
                return {required : true}
            },

        },
        methods :{
            onUpdated(){
                this.$emit('input', this.form);
            }
        }
    }
</script>

<style scoped>

</style>
