<template>
    <div class="content">
        <div class="card">
            <div class="card-heading small_red_heading">
                <small>
                    <b>{{ $store.state.language.section_c_title }}</b>
                </small>
            </div>
            <div class="card-body">
                <!--- SECTION SUICIDAIRE ---->
                <app-suicidalinfo v-model="form.suicidal"/>
                <hr/>
                <!--- SECTION HOMICIDAIRE ----->
                <app-homicidalinfo v-model="form.homicidal"/>
            </div>
        </div>
        <div class="bottom_buttons_div">
            <button class="btn btn-primary float-left" @click="PrevPage(form)">{{ $store.state.language.app_prev }}</button>
            <button class="btn btn-primary float-right" @click="NextPage(form)">{{ $store.state.language.app_next }}</button>
        </div>
    </div>
</template>

<script>
    import {commonsettings} from './mixins';
    import SuicidalInfo from './Page3/SuicidalInfo'
    import HomicidalInfo from './Page3/HomicidalInfo'

    export default {
        components :{
          "app-suicidalinfo" : SuicidalInfo,
          "app-homicidalinfo" : HomicidalInfo
        },
        mixins : [commonsettings],
        data(){
            return {
                form : {
                    suicidal: this.$store.state.form.suicidal,
                    homicidal : this.$store.state.form.homicidal
                }
            }
        },
        methods:{

        },
        computed : {

        },
        created(){
            if(this.$store.state.currentstep !== 3)
            {
                console.log("/page" + this.$store.state.currentstep);
                this.$router.push("/page" + this.$store.state.currentstep);
            }
        }
    }
</script>

<style>
    .small_red_heading{
        background-color: red!important;
        padding-top:2px;
        padding-bottom: 2px;
        color:white !important;
    }

</style>
