<template>
    <div class="content">
        <div class="card">
            <div class="card small_blue_heading">
                <small>
                    <b>{{ $store.state.language.section_d_title }}</b>
                </small>
            </div>
            <div class="card-body">
                <!---- SECTION DOCTEUR ---->
                <app-doctorinfo v-model="form.doctor"/>
                <hr/>
                <div v-if="form.doctor.doctor == 'oui'">
                    <!---- SECTION GMF ---->
                    <app-gmfinfo v-model="form.gmf"/>
                    <hr/>
                </div>
                <!---- SECTION SERVICES ---->
                <app-servicesinfo v-model="form.services"/>
                <hr/>
                <!---- SECTION INTERVENTIONS ---->
                <app-interventionsinfo v-model="form.interventions"/>
                <hr/>
                <!---- SECTION AUTRES SERVICES ---->
                <app-otherservicesinfo v-model="form.other_services"/>
            </div>
        </div>
        <div class="bottom_buttons_div">
            <button class="btn btn-primary float-left" @click="PrevPage(form)">{{ $store.state.language.app_prev }}</button>
            <button class="btn btn-primary float-right" @click="NextPage(form)">{{ $store.state.language.app_next }}</button>
        </div>
    </div>
</template>

<script>
    import {commonsettings} from './mixins'
    import DoctorInfo from './Page4/DoctorInfo'
    import GmfInfo from './Page4/GmfInfo'
    import ServicesInfo from './Page4/ServicesInfo'
    import InterventionsInfo from './Page4/InterventionsInfo'
    import OtherServicesInfo from './Page4/OtherServicesInfo'

    export default {
        data(){
            return {
                form : {
                    doctor : this.$store.state.form.services.doctor,
                    gmf : this.$store.state.form.services.gmf,
                    services : this.$store.state.form.services.services,
                    interventions : this.$store.state.form.services.interventions,
                    other_services : this.$store.state.form.services.other_services
                }
            }
        },
        components : {
          "app-doctorinfo" : DoctorInfo,
          "app-gmfinfo" : GmfInfo,
          "app-servicesinfo" : ServicesInfo,
          "app-interventionsinfo" : InterventionsInfo,
          "app-otherservicesinfo" : OtherServicesInfo
        },
        mixins : [commonsettings],
        created(){
            if(this.$store.state.currentstep !== 4)
            {
                console.log("/page" + this.$store.state.currentstep);
                this.$router.push("/page" + this.$store.state.currentstep);
            }
        }
    }
</script>

<style>

</style>
