<template>
    <div>
        <div class="row form-inline form-group">
            <div class="col-md-12">
                <label for="triggers">{{ $store.state.language.triggersinfo_trigger }}</label>
                <el-tooltip :content="$store.state.language.triggersinfo_trigger_tooltip">
                    <i class="fas fa-question-circle"></i>
                </el-tooltip>
            </div>
        </div>
        <div class="row form-inline form-group">
            <div class="col-md-12">
               <textarea
                   id="triggers"
                   name="triggers"
                   v-model.trim="triggers"
                   :class="errors.has('triggers') ? 'form-control full-width glowing-border':'form-control full-width'"
                   v-validate="{required : true}"
                   rows="4"
                   @input="onUpdated"
                   maxlength="600"
               >
               </textarea>
               <span v-show="errors.has('triggers')" class="error">
                   {{ $store.state.language.required }}
               </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TriggersInfo",
        props : {
            value :{
                type: String,
                default: ""
            }
        },
        inject: {
            $validator: '$validator'
        },
        data(){
            return {
                triggers : this.value
            }
        },
        computed :{

        },
        methods :{
            onUpdated(){
                this.$emit('input',this.triggers);
            }
        }

    }
</script>

<style scoped>

</style>