<template>
    <div>
        <div class="row form-inline form-group">
            <div class="col-md-12">
                <label >{{ $store.state.language.suicidalinfo_suicidal }}</label>
                <el-tooltip>
                    <div slot="content">
                        <span v-html="$store.state.language.suicidalinfo_suicidal_tooltip"></span>
                    </div>
                    <i class="fas fa-question-circle"></i>
                </el-tooltip>
            </div>
        </div>
        <div class="row form-inline form-group">
            <div class="col-md-6">
                <span >{{ $store.state.language.suicidalinfo_suicidal_label }}</span>
            </div>
            <div class="col-md-6">
                <select
                    id="suicidal"
                    name="suicidal"
                    v-model="suicidal.suicidal"
                    :class="errors.has('suicidal') ? 'form-control full-width glowing-border':'form-control full-width'"
                    v-validate="{required : true, is_not: '-'}"
                    @changed="onUpdated"
                >
                    <option value="-">-----</option>
                    <option :value="$store.state.language.yes">{{ $store.state.language.yes }}</option>
                    <option :value="$store.state.language.no">{{ $store.state.language.no }}</option>
                    <option :value="$store.state.language.not_evaluated">{{ $store.state.language.not_evaluated }}</option>
                </select>
                <span v-show="errors.has('suicidal')" class="error">
                    {{ $store.state.language.required }}
                </span>
            </div>
        </div>
        <transition name="fade">
            <div v-if="suicidal.suicidal === $store.state.language.yes">
                <div class="row form-inline form-group">
                    <div class="col-md-12">
                        <span>{{$store.state.language.details}}</span>
                        <el-tooltip>
                            <div slot="content">
                                <span v-html="$store.state.language.suicidalinfo_suicidal_details_tooltip"></span>
                            </div>
                            <i class="fas fa-question-circle"></i>
                        </el-tooltip>
                    </div>
                </div>
                <div class="row form-inline form-group">
                    <div class="col-md-12">
                        <textarea
                                id="suicidal_details"
                                name="suicidal_details"
                                rows="6"
                                v-model.trim="suicidal.details"
                                :class="errors.has('suicidal_details') ? 'form-control full-width glowing-border':'form-control full-width'"
                                v-validate="required"
                                @input="onUpdated"
                                maxlength="600"
                        ></textarea>
                        <span v-show="errors.has('suicidal_details')" class="error">
                            {{ $store.state.language.required }}
                        </span>
                    </div>
                </div>
                <div class="row form-inline form-group">
                    <div class="col-md-6">
                        <span >{{$store.state.language.suicidalinfo_suicidal_type}}</span>
                    </div>
                    <div class="col-md-6">
                        <select
                            id="frequency"
                            name="frequency"
                            v-model="suicidal.frequency"
                            :class="errors.has('frequency') ? 'form-control full-width glowing-border':'form-control full-width'"
                            v-validate="required"
                            @changed="onUpdated"
                        >
                            <option value="-">-----</option>
                            <option :value="$store.state.language.suicidalinfo_suicidal_occasionnal">{{$store.state.language.suicidalinfo_suicidal_occasionnal}}</option>
                            <option :value="$store.state.language.suicidalinfo_suicidal_action">{{$store.state.language.suicidalinfo_suicidal_action}}</option>
                        </select>
                        <span v-show="errors.has('frequency')" class="error">
                            {{ $store.state.language.required }}
                        </span>
                    </div>
                </div>

            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name: "SuicidalInfo",
        props : {
            value :{
                type: Object,
                default: () => {
                    return { suicidal : '-', details : '', frequency : '-'}
                }
            }
        },
        inject: {
            $validator: '$validator'
        },
        data(){
            return {
                suicidal : this.value
            }
        },
        computed :{
            required(){
                if(this.suicidal.suicidal !== this.$store.state.language.yes)
                {
                    return ''
                }

                return {required :true}
            }
        },
        methods :{
            onUpdated(){
                this.$emit('input',this.suicidal);
            }
        }
    }
</script>

<style>

</style>
