<template>
    <div>
        <div class="row form-inline form-group">
            <div class="col-md-12">
                <label for="userdoc">{{ $store.state.language.doctorinfo_doctor }}</label>
            </div>
        </div>
        <div class="row form-inline form-group">
            <div class="col-md-4">
                <select
                    id="userdoc"
                    name="userdoc"
                    :class="errors.has('userdoc') ? 'form-control full-width glowing-border':'form-control full-width'"
                    v-model="doctor.doctor"
                    v-validate="{required : true, is_not: '-'}"
                    @changed="onUpdated"
                >
                    <option value="-">-----</option>
                    <option :value="$store.state.language.yes">{{ $store.state.language.yes }}</option>
                    <option :value="$store.state.language.no">{{ $store.state.language.no }}</option>
                </select>
                <span v-show="errors.has('userdoc')" class="error">
                    {{ $store.state.language.required }}
                </span>
            </div>
        </div>
        <transition name="fade">
            <div v-if="doctor.doctor === $store.state.language.yes" class="col-12">
                <div class="row form-inline form-group">
                    <label for="doctor_name">{{ $store.state.language.doctorinfo_doctor_details }}</label>
                </div>
                <div class="row form-inline form-group">
                    <input type="text"
                           name="doctor_name"
                           id="doctor_name"
                           :class="errors.has('doctor_name') ? 'form-control full-width glowing-border':'form-control full-width'"
                           v-validate="require_doctorname"
                           v-model.trim="doctor.doctor_details"
                           @input="onUpdated"
                    >
                    <span v-show="errors.has('doctor_name')" class="error">
                        {{ $store.state.language.required }}
                    </span>

                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name: "DoctorInfo",
        props : {
            value :{
                type: Object,
                default: () => {
                    return {
                        doctor : '-',
                        doctor_details : '',
                    }
                }
            }
        },
        inject: {
            $validator: '$validator'
        },
        data(){
            return {
                doctor : this.value
            }
        },
        computed :{
            require_doctorname(){
                if(this.doctor.doctor !== '-')
                {
                    return { required : true }
                }
                return '';
            },
        },
        methods :{
            onUpdated(){
                this.$emit('input',this.doctor);
            }
        }
    }
</script>

<style scoped>

</style>
