<template>
    <div>
        <div class="row form-inline form-group">
            <!----------------------------- CHAMP PRENOM ------------------------------->
            <div class="col-md-2">
                <label for="surname"> {{ $store.state.language.userinfo_surname }}</label>
            </div>
            <div class="col-md-4">
                <input
                    id="surname"
                    name="surname"
                    type="text"
                    v-model.trim="form.surname"
                    :class="errors.has('surname') ? 'form-control full-width glowing-border':'form-control full-width'"
                    v-validate="{required : true}"
                    @input="onUpdated"
                >
                <span v-show="errors.has('surname')" class="error">
                     {{ $store.state.language.required }}
                </span>
            </div>
            <!----------------------------- CHAMP NOM ---------------------------------->
            <div class="col-md-2">
                <label for="name">{{ $store.state.language.userinfo_name }}</label>
            </div>
            <div class="col-md-4">
                <input
                    id="name"
                    name="name"
                    type="text"
                    v-model.trim="form.name"
                    :class="errors.has('name') ? 'form-control full-width glowing-border':'form-control full-width'"
                    v-validate="{required : true}"
                    @input="onUpdated"
                >
                <span v-show="errors.has('name')" class="error">
                    {{ $store.state.language.required }}
                </span>
            </div>
        </div>
        <div class="row form-inline form-group">
            <!----------------------------- CHAMP DATE DE NAISSANCE  -------------------->
            <div class="col-md-2">
                <label for="dob">{{ $store.state.language.userinfo_dob}}</label>
            </div>
            <div class="col-md-4">
                <el-date-picker
                    id="dob"
                    name="dob"
                    type="date"
                    v-model="form.dob"
                    :class="errors.has('dob') ? 'full-width glowing-border':'full-width'"
                    v-validate="{required : true, is_not : ''}"
                    @input="UpdateBDate"
                />
                <span v-show="errors.has('dob')" class="error">
                      {{ $store.state.language.required }}
                </span>
            </div>
            <!----------------------------- CHAMP NO DOSSIER ----------------------------->
            <div class="col-md-2">
                <label for="filenbr">{{ $store.state.language.userinfo_filenbr}}</label>
            </div>
            <div class="col-md-4">
                <input
                    id="filenbr"
                    name="filenbr"
                    type="text"
                    v-model.trim="form.filenbr"
                    :class="errors.has('filenbr') ? 'full-width glowing-border form-control':'full-width form-control'"
                    @input="onUpdated"
                >

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "UserInfo",
        props : {
            updated:{
                type:Function

            },
            value :{
                type: Object,
                default: () => {
                    return {
                        name : '',
                        surname : '',
                        dob : '',
                        filenbr : ''
                    };
                }
            }
        },
        inject: {
            $validator: '$validator'
        },

        data(){
            return {
                form : this.value
            }
        },
        computed :{

        },
        methods:{
            //*********** MET A JOUR LES INFOS DU FORMULAIRE LORS DE MODIFICATION
            onUpdated(){
                this.$emit('input', this.form );
            },
            // PERMET DE CONTOURNER UN BUG D'ERREUR QUI NE S'APPLIQUE PAS
            UpdateBDate(){
                if(this.form.dob === null)
                {
                    if(!this.errors.has('dob')) {
                        this.errors.add({
                            field: 'dob',
                            msg: 'invalid',
                            id : 9999
                        });
                    }
                }
                else
                {
                    if(this.errors.has('dob'))
                    {
                        this.errors.remove('dob');
                    }

                }
                this.onUpdated();
            },
        },


    }
</script>

<style>

</style>
