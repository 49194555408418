<template>
    <div class="content">
        <p>
            <h3>{{$store.state.language.information_title}}</h3>
            <ul>
                <li>{{$store.state.language.information_b1}}</li>
                <li>{{$store.state.language.information_b2}}</li>
                <li>{{$store.state.language.information_b3}}</li>
            </ul>
        </p>
        <h4>{{$store.state.language.information_transmission}}</h4>
        <p>{{$store.state.language.information_details}}
        </p>

        <div id="message">      
            <p>
                <transition name="fade">
                    <div v-if="choice === 0" class="messagefinal">
                        <h4>{{$store.state.language.information_options}}<br/><br/></h4>

                        <button class="btn btn-primary choice" @click="SendFormData(1)">{{$store.state.language.yes}}</button>&nbsp;
                        <button class="btn btn-primary choice" @click="SendFormData(2)">{{$store.state.language.no}}</button>
                    </div>
                    <div v-if="choice === 1" class="messagefinal">
                        <b><h3><p >{{$store.state.language.information_noemail}}</p></h3></b>                       
                        <h5>{{$store.state.language.information_noemail2}}</h5>
                    </div>
                    <div v-if="choice === 2" class="messagefinal">
                        <b><h4><p >{{$store.state.language.information_email}}</p></h4></b>                                            
                    </div>  
               
                </transition>
            </p>
        </div>
        <br/>
        <transition name="fade">
            <div v-if="blob !== undefined && loading === 2" class="download">
                <button class="btn" @click="DownloadPDF" :download="formname"><img src="img/pdf.svg" height="64" ><h2>Télécharger une copie de votre formulaire</h2></button>
            </div>
            <div v-if="loading === 1" class="downloading">
                <h3>Génération du formulaire en cours... <i class="fas fa-spinner fa-pulse"></i></h3>
            </div>
            <div v-if="loading === 3" class="dlerror">

            </div>    
        </transition>
        <br/>
        <h4>{{$store.state.language.information_reminder}}</h4>
        <ul>
            <li v-for="(guichet,no) in $config.listemails" v-bind:key="no">{{guichet.name}} (<a :href="'mailto:'+guichet.email">{{guichet.email}}</a>)</li>
        </ul>

    </div>
</template>

<script>
    import {commonsettings} from "./mixins";

    export default {
        name: "Page7",
        mixins : [commonsettings],
        data(){
            return {
                choice: 0,
                blob : undefined,
                url : "",
                loading: 0,
                formname: ""
            }
        },
        methods : {
            SendFormData(value){

                let identifier = this.$store.state.form.nam_info.nam == "" ? this.$store.state.form.user_info.name + "_" + this.$store.state.form.user_info.surname : this.$store.state.form.nam_info.nam;
                let dt = new Date();
                let timestamp = dt.getFullYear() + "-" + dt.getMonth() + "-" + dt.get
                this.formname = this.$store.state.form.email + "_formulaire_" + identifier + "_" + dt.getDate() + "_" + dt.getHours() + "_" + dt.getMinutes() + "_" + dt.getSeconds() + ".pdf";
                this.loading = 1;
                this.choice = value;
                axios.post(
                    '/api/makepdf',
                    {
                        action: value,
                        lang: 'fr',
                        form: this.$store.state.form,
                        dest: this.$config.listemails[this.$store.state.form.email],
                        config: this.$config,
                        formname: this.formname
                    },{ responseType:'blob',timeout: 30000})
                    .then(response => {
                        this.loading = 2;
                        this.blob=response.data; 
                        if(window.navigator.msSaveBlob)
                        {
                            this.url = "javascript:null";
                        }
                        else
                        {
                            this.url = URL.createObjectURL(this.blob);                       
                        }
                    }).catch(e => {
                        this.loading = 3;
                        this.$message({message: this.$store.state.language.form_send_error + e, type:'error', duration:5000 })
                    });
                this.$store.commit("resetform",this.$store.state.language);
            },
            DownloadPDF(){
                /* CONTOURNEMENT POUR IE 11 */ 
                if(window.navigator.msSaveOrOpenBlob)
                {
                    window.navigator.msSaveOrOpenBlob(this.blob,this.formname);
                }
                else {
                    let link = document.createElement('a');
                    link.href = this.url;
                    link.download = this.formname;
                    link.click();
                }
                return false;
            }
        },
        created(){

            if(this.$store.state.currentstep !== 7)
            {
                
                this.$router.push("/page" + this.$store.state.currentstep);
            }

        }
    }
</script>

<style scoped>
    h1{
        text-align: center;
    }
    .btninfo{
        height: 150px;
        width: 150px;
        white-space: normal;
    }
    #message {
        border: 3px double black;
        padding : 5px;
    }
    .choice{
        width: 100px;
    }
</style>
