<template>
    <div>
        <div class="row form-inline form-group">
            <div class="col-md-12">
                <label for="usergmf">{{ $store.state.language.gmfinfo_gmf }}</label>
            </div>
        </div>
        <div class="row form-inline form-group">
            <div class="col-md-4">
                <select
                    id="usergmf"
                    name="usergmf"
                    :class="errors.has('usergmf') ? 'form-control full-width glowing-border':'form-control full-width'"
                    v-model="gmf.gmf"
                >
                    <option value="-">-----</option>
                    <option :value="$store.state.language.yes">{{ $store.state.language.yes }}</option>
                    <option :value="$store.state.language.no">{{ $store.state.language.no }}</option>
                    <option :value="$store.state.language.gmfinfo_gmf_does_not_know">{{ $store.state.language.gmfinfo_gmf_does_not_know }}</option>
                </select>
                <span v-show="errors.has('usergmf')" class="error">
                    {{ $store.state.language.required }}
                </span>
            </div>
        </div>
        <transition name="fade">
            <div v-if="gmf.gmf === $store.state.language.yes" class="col-12">
                <div class="row form-inline form-group">
                    <label for="gmf_name">{{ $store.state.language.gmfinfo_gmf_details }}</label>
                </div>
                <div class="row form-inline form-group">
                    <input type="text"
                        name="gmf_name"
                        id="gmf_name"
                        :class="errors.has('gmf_name') ? 'form-control full-width glowing-border':'form-control full-width'"
                        v-validate="require_gmfname"
                        v-model.trim="gmf.gmf_details"
                    >
                    <span v-show="errors.has('gmf_name')" class="error">
                        {{ $store.state.language.required }}
                    </span>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name: "GmfInfo",
        props : {
            value :{
                type: Object,
                default: () => {
                    return {
                        gmf: '-',
                        gmf_details: '',
                    }
                }
            }
        },
        inject: {
            $validator: '$validator'
        },
        data(){
            return {
                gmf : this.value
            }
        },
        computed :{
            require_gmfname(){
                if(this.gmf.gmf !== '-')
                {
                    return { required : true }
                }
                return '';
            },
        },
        methods :{
            onUpdated(){
                this.$emit('input',this.gmf);
            }
        }
    }
</script>

<style scoped>

</style>
