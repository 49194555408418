<template>
    <div class="content">
        <div class="card panel-primary">
            <div class="card-heading small_blue_heading">
                <small>
                    <b>{{ $store.state.language.section_f_title }}</b>
                </small>
            </div>
            <div class="card-body">
                <app-requestinfo v-model="form.requestinfo"/>
            </div>
        </div>
        <div class="bottom_buttons_div">
            <button class="btn btn-primary float-left" @click="PrevPage(form)">{{ $store.state.language.app_prev }}</button>
            <button class="btn btn-primary float-right" @click="FinishForm">{{ $store.state.language.app_next }}</button>
        </div>
    </div>
</template>

<script>
    import {commonsettings} from './mixins'
    import RequestInfo from './Page6/RequestInfo'

    export default {
        name: "Page6",
        mixins : [commonsettings],
        components : {
            "app-requestinfo" : RequestInfo
        },
        data(){
            return {
                form : {
                    requestinfo : this.$store.state.form.requestinfo
                }
            }
        },
        methods:{
            FinishForm(){
                if(this.$validator.validateAll().then((result) => {
                    if(result){
                        if(confirm(this.$store.state.language.information_dialog))
                        {
                            this.$store.commit('updatePage' + this.$store.state.currentstep,this.form);
                            this.$store.commit("nextPage");
                            this.$router.push('/page' + this.$store.state.currentstep);
                        }
                    }
                    else{
                        this.$message({message: this.$store.state.language.error, type:'error', duration:5000 });
                    }
                }));

            }
        },
        created(){
            if(this.$store.state.currentstep !== 6)
            {
                this.$router.push("/page" + this.$store.state.currentstep);
            }
        }
    }
</script>

<style scoped>

</style>
