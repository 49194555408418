<template>
    <div>
        <transition name="fade">
            <!--- Moins de 14 ans --->
            <div v-if="age < 14">
                <div class="row form-inline form-group">
                    <div class="col-md-12">
                        <label v-html="$store.state.language.consentinfo_user_less_14"></label>
                    </div>
                </div>
                <div class="row form-inline form-group">
                    <div class="col-md-12">
                        <span > {{ $store.state.language.consentinfo_user_less_14_msg}}</span>
                    </div>
                </div>
                <div class="row form-inline form-group">
                    <div class="col-md-2">
                        <select
                            id="minor_less_14_consent"
                            name="minor_less_14_consent"
                            :class="errors.has('minor_less_14_consent') ? 'form-control glowing-border':'form-control'"
                            v-model="form.userless14"
                            v-validate="require_less_14"
                            @change="onUpdated"
                        >
                            <option value="-">-----</option>
                            <option :value="$store.state.language.yes">{{ $store.state.language.yes }}</option>
                            <option :value="$store.state.language.no">{{ $store.state.language.no }}</option>
                        </select>
                        <span v-show="errors.has('minor_less_14_consent')" class="error">
                            {{ $store.state.language.required }}
                        </span>
                    </div>
                    <transition name="fade">
                        <div v-if="form.userless14 === $store.state.language.yes" class="col-10 form-inline">
                            <div class="col-md-5">
                                <label> {{$store.state.language.consentinfo_user_less_14_tutor}}</label>
                            </div>
                            <div class="col-md-7">
                                <input
                                    id="tutor"
                                    name="tutor"
                                    :class="errors.has('tutor') ? 'form-control full-width glowing-border' : 'form-control full-width'"
                                    v-validate="require_less_14_tutor"
                                    @input="onUpdated"
                                    v-model="form.userless14_details"
                                >
                                <span v-show="errors.has('tutor')" class="error">
                                    {{ $store.state.language.required }}
                                </span>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </transition>
        <transition name="fade">
            <!--- 14 ans et + --->
            <div v-if="age >= 14">
                <div class="row form-inline form-group">
                    <div class="col-md-12">
                        <label v-html="$store.state.language.consentinfo_user_plus_14"></label>
                    </div>
                </div>
                <div class="row form-inline form-group">
                    <div class="col-md-12">
                        <span>{{$store.state.language.consentinfo_user_plus_14_msg}}</span>
                    </div>
                </div>
                <div class="row form-inline form-group">
                    <div class="col-md-1">
                        <select
                            id="minor_plus_14_consent"
                            name="minor_plus_14_consent"
                            :class="errors.has('minor_plus_14_consent') ? 'form-control glowing-border':'form-control'"
                            v-model="form.userplus14"
                            v-validate="require_plus_14"
                            @change="onUpdated"
                        >
                            <option value="-">-----</option>
                            <option :value="$store.state.language.yes">{{ $store.state.language.yes }}</option>
                            <option :value="$store.state.language.no">{{ $store.state.language.no }}</option>
                        </select>
                        <span v-show="errors.has('minor_plus_14_consent')" class="error">
                            {{ $store.state.language.required }}
                        </span>
                    </div>
                </div>
            </div>
        </transition>
        <transition name="fade">
            <!--- entre 14 et 17 ans --->
            <div v-if="age >= 14 && age <= 17">
                <div class="row form-inline form-group">
                    <div class="col-md-12">
                        <label v-html="$store.state.language.consentinfo_user_1417"></label>                       
                    </div>
                </div>
                <div class="row form-inline form-group">
                    <div class="col-md-12">
                        <span>{{ $store.state.language.consentinfo_user_1417_msg}}</span>                    
                    </div>
                </div>
                <div class="row form-inline form-group">
                    <div class="col-md-1">
                        <select
                            id="minor_14_17_consent"
                            name="minor_14_17_consent"
                            :class="errors.has('minor_14_17_consent') ? 'form-control glowing-border':'form-control'"
                            v-model="form.minor1417"
                            @change="onUpdated"
                        >
                            <option value="-">-----</option>
                            <option :value="$store.state.language.yes">{{ $store.state.language.yes }}</option>
                            <option :value="$store.state.language.no">{{ $store.state.language.no }}</option>
                        </select>
                        <span v-show="errors.has('minor_14_17_consent')" class="error">
                            {{ $store.state.language.required }}
                        </span>
                    </div>
                    <transition name="fade">
                        <div v-if="form.minor1417 === $store.state.language.yes" class="col-md-11">
                            <div class="form-group">
                                <div class="col-md-3">
                                    <span>{{$store.state.language.consentinfo_user_1417_contact}}</span>
                                    <select
                                        id="minor_14_17_contact"
                                        name="minor_14_17_contact"
                                        :class="errors.has('minor_14_17_contact') ? 'form-control glowing-border':'form-control'"
                                        v-model="form.minor1417contact"
                                        v-validate="require_14_17"
                                        @change="onUpdated"
                                    >
                                        <option value="-">-----</option>
                                        <option :value="$store.state.language.consentinfo_mother">{{ $store.state.language.consentinfo_mother }}</option>
                                        <option :value="$store.state.language.consentinfo_father">{{ $store.state.language.consentinfo_father }}</option>
                                        <option :value="$store.state.language.consentinfo_other">{{ $store.state.language.consentinfo_other }}</option>
                                    </select>
                                    <span v-show="errors.has('minor_14_17_contact')" class="error">
                                        {{ $store.state.language.required }}
                                    </span>
                                </div>
                                <div class="col-md-6">
                                    <span >{{$store.state.language.consentinfo_user_1417_contact_name}}</span>
                                    <input
                                        id="minor_14_17_contact_details"
                                        name="minor_14_17_contact_details"
                                        type="text"
                                        :class="errors.has('minor_14_17_contact_details') ? 'form-control full-width glowing-border' : 'form-control full-width'"
                                        v-model.trim="form.minor1417contactname"
                                        v-validate="require_14_17_contact_details"
                                        @input="onUpdated"
                                    />
                                    <span v-show="errors.has('minor_14_17_contact_details')" class="error">
                                        {{ $store.state.language.required }}
                                    </span>                                
                                </div>
                                <div class="col-md-3">
                                    <span> {{ $store.state.language.consentinfo_user_1417_contact_tel}}</span>
                                    <input
                                        id="minor_14_17_contact_phone"
                                        name="minor_14_17_contact_phone"
                                        type="tel"
                                        :class="errors.has('minor_14_17_contact_phone') ? 'form-control full-width glowing-border':'form-control full-width'"
                                        v-validate="require_14_17_contact_tel"
                                        v-mask="'(999) 999-9999'"
                                        @input="onUpdated"
                                        v-model="form.minor1417phone"
                                    />
                                    <span v-show="errors.has('minor_14_17_contact_phone')" class="error">
                                        {{ $store.state.language.required }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
                <div class="row form-inline form-group">

                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import {commonsettings} from '../mixins';

    export default {
        mixins : [commonsettings],
        name: "ConsentmentInfo",
        props : {
            value :{
                type: Object,
                default: () => {
                    return {
                        userless14 : '-',
                        userless14_details : '',
                        userplus14 : '-',
                        minor1417: '-',
                        minor1417contact: '-',
                        minor1417contactname: '',
                        minor1417phone:''
                    }
                }
            }
        },
        inject: {
            $validator: '$validator'
        },
        data(){
            return {
                age: 0,
                form : this.value
            }
        },
        computed :{
            require_plus_14(){
                if(this.age >= 14)
                {
                    return {required : true, is_not: '-'};
                }
                return '';
            },
            require_less_14(){
                if(this.age < 14)
                {
                    return {required : true, is_not: '-'};
                }
                return '';
            },
            require_less_14_tutor(){
                if(this.age < 14 && this.form.userless14 === this.$store.state.language.yes)
                {
                    return {required : true};
                }
                return ''
            },
            require_14_17(){
                if(this.form.minor1417 === this.$store.state.language.yes)
                {
                    return {required : true, is_not: '-'}
                }
                return '';
            },
            require_14_17_contact_details(){
                if(this.age >= 14 && this.age <= 17)
                {
                    return {required : true}
                }
                return '';
            },
            require_14_17_contact_tel(){
                if(this.age >= 14 && this.age <= 17)
                {
                    return {required : true, regex: this.regex_phone}
                }
                return '';
            }
        },
        methods :{
            onUpdated(){
                this.$emit('input',this.form);
            },

        },
        created(){
            this.age = this.CalculateAge();
        }


    }
</script>

<style scoped>

</style>
