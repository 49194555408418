<template>
    <div>
        <div class="row form-inline form-group">
            <div class="col-2">
                <label for="school">{{$store.state.language.schoolinfo_school}}</label>
            </div>
            <div class="col-4">
                <select
                    id="school"
                    name="school"
                    v-model="form.frequent"
                    :class="errors.has('school') ? 'form-control glowing-border':'form-control'"
                    v-validate="require_school"
                    @change="onUpdated"
                >
                    <option value="-">------</option>
                    <option :value="$store.state.language.yes">{{ $store.state.language.yes }}</option>
                    <option :value="$store.state.language.no">{{ $store.state.language.no }}</option>
                </select>
                <span v-show="errors.has('school')" class="error">
                    {{ $store.state.language.required }}
                </span>
            </div>
            <transition name="fade" >
                <div v-if="form.frequent === $store.state.language.yes" class="col-6 form-inline" id="vis">
                    <div class="col-2" >
                        <label for="school_details">
                            {{$store.state.language.details}}
                        </label>
                    </div>
                    <div class="col-10">
                        <input
                            id="school_details"
                            name="school_details"
                            v-model.trim="form.details"
                            :class="errors.has('school_details') ? 'form-control glowing-border full-width':'form-control full-width'"
                            v-validate="{required:true}"
                            @input="onUpdated"
                        >
                        <span v-show="errors.has('school_details')" class="error">
                            {{ $store.state.language.required }}
                        </span>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SchoolInfo",
        props:{
            value : {
                type: Object,
                default : () => {
                    return {
                        frequent: '-',
                        details: ''
                    };
                }
            },
        },
        inject: {
            $validator: '$validator'
        },
        data(){
            return {
                form : this.value
            }
        },
        computed: {

            require_school(){
                if(this.$store.state.form.email !== 1){
                    return '';
                }
                return {required: true, is_not: '-'}
            },
        },
        methods: {
            onUpdated(){
                this.$emit('input', this.form);
            }
        }
    }
</script>

<style scoped>
    #vis{
        padding-left:0px;
        padding-right:0px;
    }
    .error{
        border: 1px solid red;
    }
</style>
