<template>
    <div>
        <div class="row form-inline form-group">
            <div class="col-md-12">
                <label for="recom">{{ $store.state.language.recommendationsinfo_recom }}</label>
                <el-tooltip>
                    <div slot="content">
                        <span v-html="$store.state.language.recommendationsinfo_recom_tooltip"></span>
                    </div>
                    <i class="fas fa-question-circle"></i>
                </el-tooltip>
            </div>
        </div>
        <div class="row form-inline form-group">
            <div class="col-md-12">
                <textarea
                    id="recom"
                    name="recom"
                    :class="errors.has('recom') ? 'form-control full-width glowing-border':'form-control full-width'"
                    v-validate="{required : true}"
                    v-model.trim="recommendations"
                    @input="onUpdated"
                    maxlength="600"
                ></textarea>
                <span v-show="errors.has('recom')" class="error">
                    {{ $store.state.language.required }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "RecommendationsInfo",
        props : {
            value :{
                type: String,
                default: ""
            }
        },
        inject: {
            $validator: '$validator'
        },
        data(){
            return {
                recommendations : this.value
            }
        },
        computed :{

        },
        methods :{
            onUpdated(){
                this.$emit('input',this.recommendations);
            }
        }
    }
</script>

<style scoped>

</style>