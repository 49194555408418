<template>
    <div>
        <div class="row form-inline form-group">
            <div class="col-md-12">
                <label >{{ $store.state.language.homicidalinfo_homicidal }}</label>
                <el-tooltip>
                    <div slot="content">
                        <span v-html="$store.state.language.homicidalinfo_homicidal_tooltip"></span>
                    </div>
                    <i class="fas fa-question-circle"></i>
                </el-tooltip>
            </div>
        </div>
        <div class="row form-inline form-group">
            <div class="col-md-6">
                <span>{{$store.state.language.homicidalinfo_homicidal_label}}</span>
            </div>
            <div class="col-md-6">
                <select
                    id="homicidal"
                    name="homicidal"
                    v-model="homicidal.homicidal"
                    :class="errors.has('homicidal') ? 'form-control full-width glowing-border':'form-control full-width'"
                    v-validate="{required : true, is_not: '-'}"
                    @changed="onUpdated"
                >
                    <option value="-">-----</option>
                    <option :value="$store.state.language.yes">{{ $store.state.language.yes }}</option>
                    <option :value="$store.state.language.no">{{ $store.state.language.no }}</option>
                    <option :value="$store.state.language.not_evaluated">{{ $store.state.language.not_evaluated }}</option>
                </select>
            </div>
        </div>
        <transition name="fade">
            <div v-if="homicidal.homicidal === $store.state.language.yes">
                <div class="row form-inline form-group">
                    <div class="col-md-12">
                        <span>{{$store.state.language.details}}</span>
                    </div>
                </div>
                <div class="row form-inline form-group">
                    <div class="col-md-12">
                        <textarea
                            id="homicidal_details"
                            name="homicidal_details"
                            rows="6"
                            v-model.trim="homicidal.details"
                            :class="errors.has('homicidal_details') ? 'form-control full-width glowing-border':'form-control full-width'"
                            v-validate="required"
                            @input="onUpdated"
                            maxlength="600"
                        ></textarea>
                        <span v-show="errors.has('homicidal_details')" class="error">
                            {{ $store.state.language.required }}
                        </span>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name: "homicidalinfo",
        props : {
            value :{
                type: Object,
                default: () => {
                    return { homicidal : '-', details : '' }
                }
            }
        },
        inject: {
            $validator: '$validator'
        },
        data(){
            return {
                homicidal : this.value
            }
        },
        computed :{
            required(){
                if(this.homicidal.homicidal !== this.$store.state.language.yes)
                {
                    return ''
                }

                return {required :true}
            }
        },
        methods :{
            onUpdated(){
                this.$emit('input',this.homicidal);
            }
        }
    }
</script>

<style scoped>

</style>
